import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Router } from "@angular/router";
import { Urls } from "src/app/globals/urls";

@Injectable({
  providedIn: "root"
})
export class TokenRefreshService {
    private refreshInterval = 1 * 60 * 1000; // 25 minutes
    private refreshTimer: NodeJS.Timeout;

    constructor(private http: HttpClient, private router: Router) {
  }

  public startCycle(): void {
    this.initializeSession();
  }

  private initializeSession(): void {
    const nextRefreshTime = localStorage.getItem("nextRefreshTime");
    if (nextRefreshTime) {
      const currentTime = new Date().getTime();
      if (currentTime > +nextRefreshTime) {
        // Refresh immediately if the next refresh time has passed
        this.refreshSession();
      } else {
        // Set a timeout to refresh at the appropriate time
        setTimeout(() => this.refreshSession(), +nextRefreshTime - currentTime);
      }
    } else {
      // If no refresh time is set, start the refresh cycle
      this.scheduleNextRefresh();
    }
  }

  private refreshSession(): void {
    const headers = new HttpHeaders({
        'Content-Type': 'application/json' // or another appropriate type for your API
      });
    this.http.post(Urls.REFRESH_TOKEN_COOKIE, {}, {headers}).subscribe({
      next: (response) => {
        console.log("Session refreshed", response);
        this.scheduleNextRefresh();
      },
      error: (error) => {
        console.error("Error refreshing session", error);
        if (error.status === 401) {
          // Handle unauthorized error (e.g., redirect to login)
          this.router.navigate(["/login"]);
        }
      }
    });
  }

  private scheduleNextRefresh(): void {
    const nextRefreshTime = new Date().getTime() + this.refreshInterval;
    localStorage.setItem("nextRefreshTime", nextRefreshTime.toString());
    this.refreshTimer = setTimeout(() => this.refreshSession(), this.refreshInterval);
  }

  public clearSession(): void {
    clearTimeout(this.refreshTimer);
    localStorage.removeItem("nextRefreshTime");
  }
}
